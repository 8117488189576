var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('v-card-title',{staticClass:"px-2"},[_vm._v(" "+_vm._s(_vm.$t('add_a_check_queue'))+" "),_c('v-spacer'),_c('v-btn',{staticClass:"d-none d-md-block",attrs:{"color":"primary","disabled":!_vm.customer_id_pri || _vm.loading,"loading":_vm.loading},on:{"click":_vm.AddQueue}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiPlus))]),_c('span',{staticClass:"ml-1"},[_vm._v(" "+_vm._s(_vm.$t('add_queue'))+" ")])],1),_c('v-btn',{staticClass:"d-block d-md-none",attrs:{"color":"primary","icon":"","fab":"","outlined":"","disabled":!_vm.customer_id_pri || _vm.loading,"loading":_vm.loading},on:{"click":_vm.AddQueue}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiPlus))])],1)],1),_c('v-row',{staticClass:"px-2 mb-2"},[_c('v-col',{attrs:{"cols":"12","sm":"6","lg":"3"}},[_c('v-autocomplete',{attrs:{"items":_vm.dataBranchList,"item-text":"shop_name","item-value":"shop_id_pri","hide-details":"","outlined":"","dense":"","label":_vm.$t('choose_a_shop'),"no-data-text":((_vm.$t('no_data')) + " !")},model:{value:(_vm.shop_id_pri),callback:function ($$v) {_vm.shop_id_pri=$$v},expression:"shop_id_pri"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6","lg":"3"}},[_c('v-autocomplete',{attrs:{"items":_vm.customerList,"label":_vm.$t('choose_a_checker'),"placeholder":_vm.$t('input_three_charector'),"item-text":"customer_fullname","outlined":"","filter":_vm.customFilter,"dense":"","search-input":_vm.searchTextCustomer,"hide-details":"","no-data-text":_vm.statusText,"loading":_vm.searchCustomerLoading,"auto-select-first":"","item-value":"customer_id_pri"},on:{"update:searchInput":function($event){_vm.searchTextCustomer=$event},"update:search-input":function($event){_vm.searchTextCustomer=$event}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.customer_fullname)+" ("+_vm._s(item.customer_id)+") ")]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.customer_fullname)+" ("+_vm._s(item.customer_id)+") ")]}}]),model:{value:(_vm.customer_id_pri),callback:function ($$v) {_vm.customer_id_pri=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"customer_id_pri"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6","lg":"2"}},[_c('v-autocomplete',{attrs:{"items":_vm.userList,"label":_vm.$t('select_inspectors'),"item-text":"user_fullname","hide-details":"","item-value":"user_id","auto-select-first":"","no-data-text":_vm.$t('no_information'),"dense":"","outlined":""},model:{value:(_vm.user_id),callback:function ($$v) {_vm.user_id=$$v},expression:"user_id"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6","lg":"2"}},[_c('v-autocomplete',{staticClass:"set-input",attrs:{"items":_vm.roomList,"item-text":"room_name","outlined":"","dense":"","item-value":"room_id","hide-details":"","label":_vm.$t('select_examination_room'),"auto-select-first":true,"no-data-text":("" + (_vm.$t('no_information')))},model:{value:(_vm.room_id),callback:function ($$v) {_vm.room_id=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"room_id"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6","lg":"2"}},[_c('v-dialog',{ref:"datePicker",attrs:{"return-value":_vm.checkq_date,"width":"290px"},on:{"update:returnValue":function($event){_vm.checkq_date=$event},"update:return-value":function($event){_vm.checkq_date=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"hide-details":"","outlined":"","dense":"","label":_vm.$t('date')},model:{value:(_vm.checkq_date),callback:function ($$v) {_vm.checkq_date=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"checkq_date"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.isShowDate),callback:function ($$v) {_vm.isShowDate=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"isShowDate"}},[_c('v-date-picker',{attrs:{"locale":_vm.$i18n.locale,"scrollable":""},model:{value:(_vm.checkq_date),callback:function ($$v) {_vm.checkq_date=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"checkq_date"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.datePicker.save(_vm.checkq_date)}}},[_vm._v(" "+_vm._s(_vm.$t('confirm'))+" ")]),_c('v-btn',{attrs:{"text":"","color":"secondary"},on:{"click":function($event){_vm.isShowDate = false}}},[_vm._v(" "+_vm._s(_vm.$t('cancel'))+" ")]),_c('v-spacer')],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }