import opd_checking_status from '@/@fake-db/data/opd_checking_status.json'
import opd_check_status from '@/@fake-db/data/opd_check_status.json'
import opd_upload_status from '@/@fake-db/data/opd_upload_status.json'
import check from '@/api/queue/check'
import shop from '@/api/shop'
import footerDataTables from '@/components/basicComponents/footerDataTables.json'
import footerDataTablesEN from '@/components/basicComponents/footerDataTablesEN.json'
import { i18n } from '@/plugins/i18n'
import router from '@/router'
import { onMounted, ref, watch } from '@vue/composition-api'

export default function useCheckRoom() {
  const moment = require('moment')

  const opd_date = ref(moment(new Date()).format('YYYY-MM-DD'))

  const isShowDate1 = ref(false)

  const opdupload_type = ref(opd_upload_status.data[0].value)

  const searchtext = ref('')

  const opd_status_id = ref(opd_checking_status.data[0].value)

  const imageStatusData = ref(opd_upload_status.data)

  const firstCheckData = ref(opd_check_status.data)

  const statusData = ref(opd_checking_status.data)

  const dataTableList = ref([])

  const totalDataTableList = ref(0)

  const segmentId = ref(0)

  const totalPage = ref(0)

  const options = ref({})

  const opdData = ref({})

  const loading = ref(false)

  const footer = ref(i18n.locale === 'en' ? footerDataTablesEN : footerDataTables)

  const serve_comment = ref('')

  const dialogShowComment = ref(false)

  const isCancel = ref(false)

  const idCancel = ref('')

  const columns = ref([
    {
      text: '#',
      align: 'start',
      sortable: false,
      value: 'id',
      width: 30,
    },
    {
      text: `${i18n.t('a_number')} OPD`,
      value: 'opd_id',
      align: 'center',
      width: 110,
    },
    {
      text: `${i18n.t('service_number')}`,
      value: 'serve',
      align: 'center',
      width: 155,
    },
    {
      text: i18n.t('receipt_number'),
      value: 'order_id',
      align: 'center',
      width: 145,
    },
    {
      text: i18n.t('date'),
      value: 'opd_date',
      align: 'center',
      width: 160,
    },
    {
      text: i18n.t('customer'),
      value: 'customer_fullname',
      width: 250,
    },
    {
      text: i18n.t('doctor'),
      value: 'user_fullname',
      width: 170,
    },
    {
      text: i18n.t('examination_room'),
      value: 'room_name',
      width: 140,
    },
    {
      text: i18n.t('status'),
      value: 'opd_status_id',
      align: 'center',
      width: 100,
    },
    {
      text: i18n.t('check_status'),
      value: 'opd_check',
      align: 'center',
      width: 140,
    },
    {
      text: i18n.t('option'),
      value: 'actions',
      width: 250,
      align: 'center fixed',
    },
  ])

  const opdIdForPrint = ref('')

  const isPrintResultLab = ref(false)

  const isSelectRoom = ref(false)

  const showCommemt = item => {
    serve_comment.value = item.serve_comment
    dialogShowComment.value = true
  }

  const { checkList } = check

  const { shop: shopGet } = shop
  const statusShopX = ref(null)

  onMounted(async () => {
    getShopData()
  })

  const getShopData = async () => {
    try {
      const response = await shopGet()
      statusShopX.value = response.shop_x
    } catch (error) {
      console.log(error)
    }
  }

  const fetchDataTable = event => {
    loading.value = true
    checkList({
      opd_date: opd_date.value,
      opd_status_id: String(opd_status_id.value),
      opd_check: '',
      opdupload_type: opdupload_type.value,
      searchtext: searchtext.value,
      active_page: options.value.page,
      per_page: options.value.itemsPerPage == -1 ? '0' : options.value.itemsPerPage,
    }).then(res => {
      const { data, count, count_of_page, segment } = res

      dataTableList.value = data
      totalDataTableList.value = count
      segmentId.value = segment
      totalPage.value = count_of_page
      if (event == 'add') {
        options.value.page = 1
      }
      loading.value = false
    })
  }

  const detail = (id, status, statusCancel, serve_id) => {
    if (statusCancel == '0') {
      router.push({
        name: 'ShowDetailCheck',
        params: { id },
      })
    } else if (status == '0') {
      router.push({
        name: 'RoomCheckDetail',
        query: { id, serve_id },
      })
    } else {
      router.push({
        name: 'ShowDetailCheck',
        params: { id },
      })
    }
  }

  const editOpd = id => {
    router.push({
      name: 'EditChecking',
      params: { id },
    })
  }

  const gotoSaveLab = id => {
    router.push({
      name: 'EditChecking',
      params: { id },
      query: { status: 'lab' },
    })
  }

  const createReceipt = (id, date) => {
    router.push({
      name: 'creare-order-opd',
      params: { id },
      query: { date },
    })
  }

  const editDoctorAndRoom = (item, status) => {
    if (status == 1) {
      opdData.value = item
      isSelectRoom.value = true
    } else {
      router.push({
        name: 'Checking',
        query: {
          id: item.opd_id,
          serve_id: item.serve ? item.serve.serve_id : '',
        },
      })
    }
  }

  watch([searchtext, opd_status_id, opdupload_type, opd_date, options], (newvalue, oldvalue) => {
    if (
      newvalue[0] !== oldvalue[0] ||
      newvalue[1] !== oldvalue[1] ||
      newvalue[2] !== oldvalue[2] ||
      newvalue[3] !== oldvalue[3]
    ) {
      options.value.page = 1
    }
    fetchDataTable()
  })

  return {
    opd_date,
    isShowDate1,
    opdupload_type,
    searchtext,
    opd_status_id,
    dialogShowComment,
    imageStatusData,
    firstCheckData,
    statusData,
    createReceipt,
    serve_comment,
    dataTableList,
    totalDataTableList,
    segmentId,
    options,
    loading,
    footer,
    columns,
    idCancel,
    gotoSaveLab,
    detail,
    editOpd,
    editDoctorAndRoom,
    fetchDataTable,
    showCommemt,
    isSelectRoom,
    opdData,
    isCancel,
    opdIdForPrint,
    isPrintResultLab,
    totalPage,
    statusShopX,
  }
}
