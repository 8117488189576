<template>
  <div>
    <AddCheckQueue @onAdd="fetchDataTable('add')" />
    <v-card class="mt-3">
      <v-card-title class="px-2">
        {{ $t('Queue-list-check') }}
      </v-card-title>
      <v-row class="px-2">
        <v-col cols="12" md="6" class="py-0" lg="3">
          <v-text-field
            v-model="searchtext"
            :label="$t('search')"
            outlined
            dense
            :placeholder="$t('wordingSearch')"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6" class="py-0" lg="3">
          <v-dialog ref="datePicker1" v-model.trim="isShowDate1" :return-value.sync="opd_date" width="290px">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field v-model.trim="opd_date" outlined dense :label="$t('date')" v-bind="attrs" v-on="on">
                <template v-slot:append>
                  <v-tooltip color="#212121" top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon color="primary" v-bind="attrs" v-on="on" @click="opd_date = ''">
                        {{ icons.mdiCalendarBlank }}
                      </v-icon>
                    </template>
                    <span>{{ $t('all_dates') }}</span>
                  </v-tooltip>
                </template>
              </v-text-field>
            </template>
            <v-date-picker v-model.trim="opd_date" :locale="$i18n.locale" scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="$refs.datePicker1.save(opd_date)">
                {{ $t('confirm') }}
              </v-btn>
              <v-btn text color="secondary" @click="isShowDate1 = false">
                {{ $t('cancel') }}
              </v-btn>
              <v-spacer></v-spacer>
            </v-date-picker>
          </v-dialog>
        </v-col>
        <v-col cols="12" class="py-0" md="6" lg="3">
          <v-select
            v-model.trim="opdupload_type"
            class="set-text"
            :items="imageStatusData"
            :label="$t('picture_status')"
            outlined
            dense
            :item-text="$i18n.locale"
            item-value="value"
          ></v-select>
        </v-col>
        <v-col cols="12" class="py-0" md="6" lg="3">
          <v-select
            v-model.trim="opd_status_id"
            class="set-text"
            :items="statusData"
            :label="$t('status')"
            outlined
            dense
            :item-text="$i18n.locale"
            item-value="value"
          ></v-select>
        </v-col>
      </v-row>
      <v-data-table
        :headers="columns"
        :items="dataTableList"
        :options.sync="options"
        :server-items-length="totalDataTableList"
        :footer-props="footer"
        :loading="loading"
        hide-default-footer
        disable-sort
        :loading-text="$t('data_loading')"
        :no-data-text="$t('no_information')"
      >
        <template v-slot:[`item.id`]="{ index }">
          {{ segmentId + index + 1 }}
        </template>
        <template v-slot:[`item.opd_id`]="{ item }">
          <span v-if="item.opd_check == '0'">{{ item.opd_code }}</span>
          <span v-else>
            <v-tooltip v-if="item.detail" color="#212121" right>
              <template v-slot:activator="{ on, attrs }">
                <span
                  class="primary--text font-weight-medium"
                  style="cursor: pointer"
                  v-bind="attrs"
                  v-on="on"
                  @click="detail(item.opd_id, item.opd_check, item.opd_status_id)"
                  >{{ item.opd_code }}</span
                >
              </template>
              <span>
                <ol class="py-0 my-0 mx-0 pl-2 pr-0">
                  <li v-for="(i, index) in item.detail" :key="index">{{ i.name }} *{{ i.amount }}</li>
                </ol>
              </span>
            </v-tooltip>
            <span
              v-else
              class="primary--text font-weight-medium"
              style="cursor: pointer"
              @click="detail(item.opd_id, item.opd_check, item.opd_status_id)"
              >{{ item.opd_code }}</span
            >
          </span>
        </template>
        <template v-slot:[`item.serve`]="{ item }">
          <span
            v-if="item.serve && item.opd_status_id == '0'"
            style="cursor: pointer"
            class="primary--text font-weight-semibold"
            @click="showCommemt(item)"
            >{{ item.serve.serve_code }}</span
          >
          <router-link
            v-if="item.serve && item.opd_status_id != '0'"
            class="font-weight-medium text-decoration-none"
            :to="{
              name: 'showDetailService',
              params: { id: item.serve.serve_id },
            }"
          >
            {{ item.serve.serve_code }}
          </router-link>
          <span v-if="!item.serve"> - </span>
        </template>
        <template v-slot:[`item.order_id`]="{ item }">
          <router-link
            v-if="item.order_id != null"
            class="font-weight-medium text-decoration-none"
            :to="{ name: 'order-detail', params: { id: item.order_id_pri } }"
          >
            {{ item.order_id }}
          </router-link>
          <span v-else> - </span>
        </template>
        <template v-slot:[`item.opd_date`]="{ item }">
          <ConversDateTime :datetime="item.opd_date" /> {{ item.opd_time }}
        </template>
        <template v-slot:[`item.customer_fullname`]="{ item }">
          {{ item.customer_fullname }}
        </template>
        <template v-slot:[`item.opd_status_id`]="{ item }">
          <span v-if="item.opd_status_id == 0">
            <v-chip
              label
              small
              color="error"
              :class="`v-chip-${$vuetify.theme.dark ? 'dark' : 'light'}-bg error--text`"
            >
              {{ $t('cancel') }}
            </v-chip>
          </span>
          <span v-if="item.opd_status_id == 1">
            <v-chip
              label
              small
              color="warning"
              :class="`v-chip-${$vuetify.theme.dark ? 'dark' : 'light'}-bg warning--text`"
            >
              {{ $t('wait') }}
            </v-chip>
          </span>
          <span v-if="item.opd_status_id == 2">
            <v-chip
              label
              small
              color="primary"
              :class="`v-chip-${$vuetify.theme.dark ? 'dark' : 'light'}-bg primary--text`"
            >
              {{ $t('examination_room') }}
            </v-chip>
          </span>
          <span v-if="item.opd_status_id == 4">
            <v-chip
              label
              small
              color="primary"
              :class="`v-chip-${$vuetify.theme.dark ? 'dark' : 'light'}-bg primary--text`"
            >
              {{ $t('creater_receipt') }}
            </v-chip>
          </span>
          <span v-if="item.opd_status_id == 5">
            <v-chip
              label
              small
              color="warning"
              :class="`v-chip-${$vuetify.theme.dark ? 'dark' : 'light'}-bg warning--text`"
            >
              {{ $t('wait_pay') }}
            </v-chip>
          </span>
          <span v-if="item.opd_status_id == 6">
            <v-chip
              label
              small
              color="success"
              :class="`v-chip-${$vuetify.theme.dark ? 'dark' : 'light'}-bg success--text`"
            >
              {{ $t('completed') }}
            </v-chip>
          </span>
        </template>
        <template v-slot:[`item.opd_check`]="{ item }">
          <span v-if="item.opd_check == 0">
            <v-chip
              label
              small
              color="warning"
              :class="`v-chip-${$vuetify.theme.dark ? 'dark' : 'light'}-bg warning--text`"
            >
              {{ $t('wait') }}
            </v-chip>
          </span>
          <span v-if="item.opd_check == 1">
            <v-chip
              label
              small
              color="success"
              :class="`v-chip-${$vuetify.theme.dark ? 'dark' : 'light'}-bg success--text`"
            >
              {{ $t('completed') }}
            </v-chip>
          </span>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip color="#212121" top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-if="item.opd_status_id == '4' && item.opd_check == '1'"
                icon
                color="primary"
                :disabled="statusShopX == 1"
                v-bind="attrs"
                @click="createReceipt(item.opd_id, item.opd_date)"
                v-on="on"
              >
                <v-icon>{{ icons.mdiReceipt }}</v-icon>
              </v-btn>
              <v-btn v-else icon disabled color="primary" v-bind="attrs" v-on="on">
                <v-icon>{{ icons.mdiReceipt }}</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('create_receipt') }} </span>
          </v-tooltip>
          <v-tooltip color="#212121" top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-if="
                  (item.opd_check == '0' || item.opd_status_id == '1' || item.opd_status_id == '2') &&
                  item.opd_status_id != '0'
                "
                icon
                small
                color="primary"
                v-bind="attrs"
                @click="editDoctorAndRoom(item, item.order_id_pri ? '1' : '2')"
                v-on="on"
              >
                <v-icon>{{ icons.mdiCheckCircleOutline }}</v-icon>
              </v-btn>
              <v-btn v-else small icon disabled color="primary" v-bind="attrs" v-on="on">
                <v-icon>{{ icons.mdiCheckCircleOutline }}</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('check') }}</span>
          </v-tooltip>
          <v-tooltip color="#212121" top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                :disabled="item.checklab_add_id == '0' && item.checklab_edit_id == '0'"
                color="primary"
                v-bind="attrs"
                @click="gotoSaveLab(item.opd_id)"
                v-on="on"
              >
                <v-icon>{{ icons.mdiFlaskOutline }}</v-icon>
              </v-btn>
            </template>
            <span> {{ item.checklab_add_id == '1' ? $t('save_lab') : $t('lab_results_edit') }}</span>
          </v-tooltip>
          <v-tooltip color="#212121" top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-if="item.opd_status_id == '6' && item.opd_check == '0'"
                icon
                disabled
                small
                color="primary"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>{{ icons.mdiPencilOutline }}</v-icon>
              </v-btn>
              <v-btn
                v-else
                icon
                :disabled="item.check_edit_id == '0'"
                color="primary"
                v-bind="attrs"
                @click="editOpd(item.opd_id)"
                v-on="on"
              >
                <v-icon>{{ icons.mdiPencilOutline }}</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('edit') }}</span>
          </v-tooltip>
          <v-tooltip color="#212121" top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                small
                :disabled="item.print_lab == '0'"
                color="primary"
                v-bind="attrs"
                @click="
                  opdIdForPrint = item.opd_id
                  isPrintResultLab = true
                "
                v-on="on"
              >
                <v-icon>{{ icons.mdiPrinter }}</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('print_lab') }}</span>
          </v-tooltip>
          <v-tooltip color="#212121" top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                small
                :disabled="item.check_cancel_id == '0'"
                color="primary"
                v-bind="attrs"
                @click="
                  idCancel = item.opd_id
                  isCancel = true
                "
                v-on="on"
              >
                <v-icon>{{ icons.mdiClose }}</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('cancel') }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
      <v-divider></v-divider>
      <Pagination
        :page-data="options.page"
        :page-count="totalPage"
        :segment-id="+segmentId"
        :count-list="dataTableList.length"
        :total="+totalDataTableList"
        @pageChanged="
          page => {
            options.page = page
            fetchDataTable()
          }
        "
        @itemChanged="
          items => {
            options.itemsPerPage = items
            options.page = 1
            fetchDataTable()
          }
        "
      />
    </v-card>
    <v-dialog v-model="dialogShowComment" max-width="400">
      <v-card>
        <v-card-title class="py-1">
          <span>{{ $t('note') }}</span>
          <v-spacer></v-spacer>
          <span>
            <v-btn color="error" icon @click="dialogShowComment = false">
              <v-icon>{{ icons.mdiClose }}</v-icon>
            </v-btn>
          </span>
        </v-card-title>
        <v-card-text align="center">
          <span v-html="serve_comment"></span>
        </v-card-text>
      </v-card>
    </v-dialog>
    <CancelBlock :id="idCancel" v-model="isCancel" path="check/status" @onUpdate="fetchDataTable" />
    <SelectRoom v-model="isSelectRoom" :opd-data="opdData" />
    <ResultLabelOptions :id="[opdIdForPrint]" v-model="isPrintResultLab" />
  </div>
</template>

<script>
import {
  mdiCalendarBlank,
  mdiPencilOutline,
  mdiPrinter,
  mdiCheckCircleOutline,
  mdiClose,
  mdiReceipt,
  mdiFlaskOutline,
} from '@mdi/js'
import AddCheckQueue from './AddCheckQueue.vue'
import useCheckRoom from './useCheckRoom'
import ConversDateTime from '@/components/basicComponents/ConversDateTime.vue'
import CancelBlock from '@/components/basicComponents/CancelBlock.vue'
import SelectRoom from './SelectRoom.vue'
import ResultLabelOptions from '@/components/prints/ResultLabelOptions.vue'
import Pagination from '@/components/basicComponents/Pagination.vue'

export default {
  components: {
    AddCheckQueue,
    ConversDateTime,
    CancelBlock,
    SelectRoom,
    ResultLabelOptions,
    Pagination,
  },
  setup() {
    return {
      ...useCheckRoom(),
      icons: {
        mdiCalendarBlank,
        mdiPencilOutline,
        mdiPrinter,
        mdiCheckCircleOutline,
        mdiClose,
        mdiReceipt,
        mdiFlaskOutline,
      },
    }
  },
}
</script>

<style lang="scss" scoped></style>
