<template>
  <div>
    <v-card>
      <v-card-title class="px-2">
        {{ $t('add_a_check_queue') }} <v-spacer></v-spacer>
        <v-btn
          color="primary"
          class="d-none d-md-block"
          :disabled="!customer_id_pri || loading"
          :loading="loading"
          @click="AddQueue"
        >
          <v-icon>{{ icons.mdiPlus }}</v-icon>
          <span class="ml-1">
            {{ $t('add_queue') }}
          </span>
        </v-btn>
        <v-btn
          color="primary"
          class="d-block d-md-none"
          icon
          fab
          outlined
          :disabled="!customer_id_pri || loading"
          :loading="loading"
          @click="AddQueue"
        >
          <v-icon>{{ icons.mdiPlus }}</v-icon>
        </v-btn>
      </v-card-title>
      <v-row class="px-2 mb-2">
        <v-col cols="12" sm="6" lg="3">
          <v-autocomplete
            v-model="shop_id_pri"
            :items="dataBranchList"
            item-text="shop_name"
            item-value="shop_id_pri"
            hide-details
            outlined
            dense
            :label="$t('choose_a_shop')"
            :no-data-text="`${$t('no_data')} !`"
          >
          </v-autocomplete>
        </v-col>
        <v-col cols="12" md="6" lg="3">
          <v-autocomplete
            v-model.trim="customer_id_pri"
            :items="customerList"
            :label="$t('choose_a_checker')"
            :placeholder="$t('input_three_charector')"
            item-text="customer_fullname"
            outlined
            :filter="customFilter"
            dense
            :search-input.sync="searchTextCustomer"
            hide-details
            :no-data-text="statusText"
            :loading="searchCustomerLoading"
            auto-select-first
            item-value="customer_id_pri"
          >
            <template v-slot:item="{ item }">
              {{ item.customer_fullname }}
              ({{ item.customer_id }})
            </template>
            <template v-slot:selection="{ item }">
              {{ item.customer_fullname }}
              ({{ item.customer_id }})
            </template>
          </v-autocomplete>
        </v-col>
        <v-col cols="12" md="6" lg="2">
          <v-autocomplete
            v-model="user_id"
            :items="userList"
            :label="$t('select_inspectors')"
            item-text="user_fullname"
            hide-details
            item-value="user_id"
            auto-select-first
            :no-data-text="$t('no_information')"
            dense
            outlined
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" md="6" lg="2">
          <v-autocomplete
            v-model.trim="room_id"
            :items="roomList"
            class="set-input"
            item-text="room_name"
            outlined
            dense
            item-value="room_id"
            hide-details
            :label="$t('select_examination_room')"
            :auto-select-first="true"
            :no-data-text="`${$t('no_information')}`"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" md="6" lg="2">
          <v-dialog ref="datePicker" v-model.trim="isShowDate" :return-value.sync="checkq_date" width="290px">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model.trim="checkq_date"
                hide-details
                outlined
                dense
                :label="$t('date')"
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model.trim="checkq_date" :locale="$i18n.locale" scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="$refs.datePicker.save(checkq_date)">
                {{ $t('confirm') }}
              </v-btn>
              <v-btn text color="secondary" @click="isShowDate = false">
                {{ $t('cancel') }}
              </v-btn>
              <v-spacer></v-spacer>
            </v-date-picker>
          </v-dialog>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import { mdiPlus } from '@mdi/js'
import customerSelection from '@/api/customerSelection'
import shop from '@/api/shop'
import { i18n } from '@/plugins/i18n'
import userRole from '@/api/userRole'
import examinationRoom from '@/api/systemSetting/examinationRoom'
import queue from '@/api/queue/queue'
import store from '@/store'
import branch from '@/api/branch'

export default {
  setup(props, { emit }) {
    const customerList = ref([])
    const customer_id_pri = ref('')
    const searchTextCustomer = ref('')
    const statusText = ref('')
    const searchCustomerLoading = ref(false)
    const shop_id_pri = ref('')
    const user_id = ref('')
    const userList = ref([])
    const room_id = ref('')
    const roomList = ref([])
    const loading = ref(false)
    const dataBranchList = ref([])
    const moment = require('moment')
    const checkq_date = ref(moment(new Date()).format('YYYY-MM-DD'))
    const isShowDate = ref(false)
    const { customerSelectionList } = customerSelection
    const { shop: shopGet } = shop
    const { userRoleList } = userRole
    const { examinationRoomList } = examinationRoom
    const { queueAdd } = queue

    const AddQueue = () => {
      loading.value = true
      queueAdd({
        shop_id_pri: shop_id_pri.value,
        customer_id_pri: customer_id_pri.value,
        user_id: user_id.value,
        room_id: room_id.value,
        checkq_date: checkq_date.value,
        checkq_type_id: '1',
        opd_type_id: '1',
        lang: i18n.locale,
      }).then(res => {
        store.commit('app/ALERT', {
          message: res.response ? 'add_a_check_queue_success' : res.message,
          color: res.response ? 'success' : 'error',
        })
        emit('onAdd')
        store.commit('app/setStatusUpdateHeader')
        customer_id_pri.value = ''
        searchTextCustomer.value = ''
        loading.value = false
      })
    }

    branch.branchList().then(res => {
      dataBranchList.value = res
      shopGet().then(res => {
        dataBranchList.value.unshift(res)
        shop_id_pri.value = res.shop_id_pri
      })
    })

    userRoleList({
      role_id: [1, 3],
    }).then(res => {
      userList.value = res
      user_id.value = localStorage.getItem('user_id')
    })

    // shopGet().then(res => {
    //   shop_id_pri.value = res.shop_id_pri
    // })

    examinationRoomList({
      searchtext: '',
      room_status_id: 1,
    }).then(res => {
      roomList.value = res.data
      room_id.value = res.data[0].room_id
    })

    const customFilter = (item, queryText) => {
      const textOne = item.customer_fullname.toLowerCase()
      const textThree = item.customer_id.toLowerCase()
      const textFour = item.customer_tel.toLowerCase()
      const searchText = queryText.toLowerCase()

      return textOne.indexOf(searchText) > -1 || textThree.indexOf(searchText) > -1 || textFour.indexOf(searchText) > -1
    }

    const getCustomerList = () => {
      customerSelectionList({
        searchtext: searchTextCustomer.value,
        shop_id_pri: shop_id_pri.value,
      }).then(res => {
        customerList.value = res
        searchCustomerLoading.value = false
        statusText.value = !res.length ? i18n.t('no_data') : i18n.t('input_three_charector')
      })
    }

    watch(searchTextCustomer, value => {
      if (value && value.length >= 3) {
        statusText.value = i18n.t('data_loading')
        searchCustomerLoading.value = true
        getCustomerList()
      } else {
        customerList.value = []
        statusText.value = i18n.t('input_three_charector')
      }
    })

    watch(shop_id_pri, () => {
      searchTextCustomer.value = ''
      statusText.value = i18n.t('input_three_charector')
      customerList.value = []
      customer_id_pri.value = ''
    })

    return {
      customerList,
      customer_id_pri,
      searchTextCustomer,
      statusText,
      searchCustomerLoading,
      shop_id_pri,
      user_id,
      userList,
      room_id,
      dataBranchList,
      roomList,
      checkq_date,
      isShowDate,
      customFilter,
      AddQueue,
      loading,
      icons: {
        mdiPlus,
      },
    }
  },
}
</script>
